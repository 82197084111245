<template>
  <div class="">
    <v-layout row wrap class="d-flex justify-center pa-5">
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text"> <router-link to="/">Dashboard</router-link> / Reports</h1>
      </v-flex>
      <v-flex xs12 md4 class="pa-2">
        <div class="rounded-corners pa-3 grey darken-4">
          <h2 class="primary--text">Total Bookings</h2>
          <p>{{ totalBookings }}</p>
        </div>
      </v-flex>
      <v-flex xs12 md4 class="pa-2">
        <div class="rounded-corners pa-3 grey darken-4">
          <h2 class="primary--text">Cancelled Bookings</h2>
          <p>{{ cancelledBookings }}</p>
        </div>
      </v-flex>
      <v-flex xs12 md4 class="pa-2">
        <div class="rounded-corners pa-3 grey darken-4">
          <h2 class="primary--text">Abandoned Bookings</h2>
          <p>{{ abandonedBookings }}</p>
        </div>
      </v-flex>
      <v-flex xs12 md4 class="pa-2">
        <div class="rounded-corners pa-3 grey darken-4">
          <h2 class="primary--text">Bookings Today</h2>
          <p>{{ totalBookingsToday }}</p>
        </div>
      </v-flex>
      <v-flex xs12 md4 class="pa-2">
        <div class="rounded-corners pa-3 grey darken-4">
          <h2 class="primary--text">Bookings This Month</h2>
          <p>{{ totalBookingsThisMonth }}</p>
        </div>
      </v-flex>
      
      <v-flex xs12 md4 class="pa-2">
        <div class="rounded-corners pa-3 grey darken-4">
          <h2 class="primary--text">Bookings Last 3 Months</h2>
          <p>{{ totalBookingsLast3Months }}</p>
        </div>
      </v-flex>
      <v-flex xs12 md4 class="pa-2">
        <div class="rounded-corners pa-3 grey darken-4">
          <h2 class="primary--text">Revenue Today</h2>
          <p>{{ totalRevenueToday }} <span class="primary--text">AED</span></p>
        </div>
      </v-flex>
      <v-flex xs12 md4 class="pa-2">
        <div class="rounded-corners pa-3 grey darken-4">
          <h2 class="primary--text">Revenue This Month</h2>
          <p>
            {{ totalRevenueThisMonth }} <span class="primary--text">AED</span>
          </p>
        </div>
      </v-flex>
      <v-flex xs12 md4 class="pa-2">
        <div class="rounded-corners pa-3 grey darken-4">
          <h2 class="primary--text">Revenuse Last 3 Months</h2>
          <p>
            {{ totalRevenueLast3Months }} <span class="primary--text">AED</span>
          </p>
        </div>
      </v-flex>
      <v-flex xs12 md6 class="pa-2">
        <div class="rounded-corners pa-3 grey darken-4">
          <h2 class="primary--text">Bookings this Month</h2>
          <v-sparkline :value="dailyBookingsSparklineData"></v-sparkline>
          <v-row dense>
            <v-col
              v-for="(date, index) in datesForSparkline"
              :key="index"
              class="text-center pa-0"
              :style="{ width: dateWidth + '%' }"
            >
              <small class="grey--text">{{ date }}</small>
            </v-col>
          </v-row>
        </div>
      </v-flex>
      <v-flex
        xs12
        md2
        class="pa-2"
        v-for="item in bookingsGroupedByDuration"
        :key="item._id"
      >
        <div
          class="primary d-flex align-center justify-center"
          style="height: 100%; flex-direction: column"
        >
          <p class="black--text">Duration</p>
          <h1 class="f-alfa black--text" style="font-size: 32px">
            {{ item._id }} min
          </h1>
          <h3 class="black--text">Bookings - {{ item.totalCount }}</h3>
        </div>
      </v-flex>
      <v-flex xs12 md4 class="pa-2">
        <h2 class="primary--text">Bookings By Channel</h2>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left uppercase primary--text">#</th>
                <th class="text-left uppercase primary--text">Channel</th>
                <th class="text-left uppercase primary--text">Bookings</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in bookingsGroupedByChannel"
                :key="item._id"
              >
                <td>{{ index + 1 }}</td>
                <td v-if="item._id != null">{{ item._id }}</td>
                <td v-else>Others</td>
                <td>{{ item.totalCount }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
      <v-flex xs12 md4 class="pa-2">
        <h2 class="primary--text">Bookings By Payment Mode</h2>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left uppercase primary--text">#</th>
                <th class="text-left uppercase primary--text">Mode</th>
                <th class="text-left uppercase primary--text">Bookings</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in bookingsGroupedByPaymentMode"
                :key="item._id"
              >
                <td>{{ index + 1 }}</td>
                <td v-if="item._id != null">{{ item._id }}</td>
                <td v-else>Others</td>
                <td>{{ item.totalCount }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
      <v-flex xs12 md4>
       
      </v-flex>
      <v-flex xs12 md12 class="pa-4">
        <h2 class="primary--text">Bookings By Schedule</h2>
        <div class="grey darken-4 rounded-corders d-flex justify-space-between">
          <div
            v-for="item in bookingsGroupedByTime"
            :key="item._id"
            class="pa-3"
          >
            <h3 class="primary--text">{{ item._id }}</h3>
            <p class="pa-0 ma-0">{{ item.totalCount }}</p>
          </div>
        </div>
      </v-flex>
      <v-flex xs12 md4 class="pa-2">
        <div class="rounded-corners pa-3 grey darken-4">
          <h2 class="primary--text">Average Booking Amount</h2>
          <p>{{ averageBookingAmount }} <strong>AED</strong></p>
        </div>
      </v-flex>
      <v-flex xs12 md4 class="pa-2">
        <div class="rounded-corners pa-3 grey darken-4">
          <h2 class="primary--text">Average Passenger Weight</h2>
          <p>{{ averagePassengerWeight | numberFormat }} <strong>KG</strong></p>
        </div>
      </v-flex>
      <v-flex xs12 md4 class="pa-2">
        
      </v-flex>
      <!-- <v-flex xs12 md6 class="mb-5 pa-5">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left uppercase primary--text">#</th>
                <th class="text-left uppercase primary--text">Date</th>
                <th class="text-left uppercase primary--text">Bookings</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in sortedByDate" :key="item._id">
                <td>{{ index + 1 }}</td>
                <td>{{ item._id }}</td>
                <td>{{ item.count }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex> -->
      <!-- <v-flex xs12 md6 class="pa-5">
        <h2>Confirmed Bookings - {{ confirmed }}</h2>
        <h2>Cancelled Bookings - {{ cancelled }}</h2>
        <h2>Abandoned Bookings - {{ abandoned }}</h2>
      </v-flex> -->
    </v-layout>
  </div>
</template>
<script>
import { BASE_URL } from "../../config";
import Axios from "axios";
export default {
  data() {
    return {
      totalBookings : 0,
      cancelledBookings : 0,
      abandonedBookings : 0,
      totalBookingsToday: 0,
      totalBookingsThisMonth: 0,
      totalBookingsLast3Months: 0,
      totalBookingsByAgents: 0,
      bookingsGroupedByAgent: [],
      bookingsGroupedByDuration: [],
      bookingsGroupedByTime: [],
      totalRevenueToday: 0,
      totalRevenueThisMonth: 0,
      totalRevenueLast3Months: 0,
      bookingsGroupedByChannel: [],
      averageBookingAmount: 0,
      averagePassengerWeight: 0,
      bookingsGroupedByPaymentMode: [],
      bookingsGroupedByHelicopter: [],
      dailyBookingsCurrentMonth: [],
      bookingsWithRemarks: 0,
      privateVsPublicBookings: [],
      confirmed: 0,
      cancelled: 0,
      abandoned: 0,
      sortedByDate: [],
      dailyBookingsSparklineData: [], // Data for Vuetify sparkline
      datesForSparkline: [],
    };
  },
  mounted() {
    this.fetchReports();
  },
  methods: {
    async fetchReports() {
      const headers = {
        Authorization:
          "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTQ0ODMyYjliYWFmMDVkMzNhNDEwYTMiLCJjaGFubmVsIjoiQm9va2luZy5jb20iLCJjcmVhdGVkQXQiOiIyMDIzLTExLTAzVDA1OjIwOjQzLjg2NVoiLCJ1cGRhdGVkQXQiOiIyMDIzLTExLTAzVDA1OjIwOjQzLjg2NVoiLCJfX3YiOjAsImlhdCI6MTY5OTE3MDEzOX0.iS7s7eHzP_yTGD0WIFYEoFSo9HvAuVfhawHkRpv2bls",
      };
      let url = BASE_URL + "/booking/reports/all";
      let { data } = await Axios.get(url, { headers });
      this.totalBookings = data.totalBookings,
      this.abandonedBookings = data.abandonedBookings,
      this.cancelledBookings = data.cancelledBookings,
      this.totalBookingsToday = data.totalBookingsToday;
      this.totalBookingsThisMonth = data.totalBookingsThisMonth;
      this.totalBookingsLast3Months = data.totalBookingsLast3Months;
      this.totalBookingsByAgents = data.totalBookingsByAgents;
      this.bookingsGroupedByAgent = data.bookingsGroupedByAgent;
      this.bookingsGroupedByDuration = data.bookingsGroupedByDuration;
      this.bookingsGroupedByTime = data.bookingsGroupedByTime.sort(
        (a, b) => a._id - b._id
      );
      this.totalRevenueToday = data.totalRevenueToday;
      this.totalRevenueThisMonth = data.totalRevenueThisMonth;
      this.totalRevenueLast3Months = data.totalRevenueLast3Months;
      this.bookingsGroupedByChannel = data.bookingsGroupedByChannel;
      this.averageBookingAmount = data.averageBookingAmount;
      this.averagePassengerWeight = data.averagePassengerWeight;
      this.bookingsGroupedByPaymentMode = data.bookingsGroupedByPaymentMode;
      this.bookingsGroupedByHelicopter = data.bookingsGroupedByHelicopter;
      this.dailyBookingsCurrentMonth = data.dailyBookingsCurrentMonth;
      this.bookingsWithRemarks = data.bookingsWithRemarks;
      this.privateVsPublicBookings = data.privateVsPublicBookings;

      // Assigning any additional data that might be present in the response
      this.confirmed = data.confirmedBookingsCount || 0;
      this.cancelled = data.cancelledBookingsCount || 0;
      this.abandoned = data.pendingPaymentBookingsCount || 0;
      this.sortedByDate = data.bookingsByDate || [];

      this.processDailyBookingsSparklineData(data.dailyBookingsCurrentMonth);
    },
    processDailyBookingsSparklineData(dailyBookings) {
      // Get the maximum day of the current month
      const today = new Date();
      const daysInMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      ).getDate();

      // Initialize arrays for sparkline data and dates
      const sparklineData = Array(daysInMonth).fill(0);
      const dates = Array.from({ length: daysInMonth }, (_, i) => i + 1);

      // Populate the arrays with the totalCount values from dailyBookings
      dailyBookings.forEach((day) => {
        const dayIndex = day._id - 1; // Assuming _id is the day of the month (1-indexed)
        sparklineData[dayIndex] = day.totalCount;
      });

      this.dailyBookingsSparklineData = sparklineData;
      this.datesForSparkline = dates;
    },
  },
};
</script>